import React from "react";
import "./styles.css";

import whats from "../../assets/whats.png";
import email from "../../assets/email.png";
import logo from "../../assets/logo_rodape.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure>
            <img src={logo} alt="Logo" />
          </figure>

          <div className="text1">
            <p className="top">Endereço:</p>

            <p className="bottom">
              Rua João Gonçalves de Oliveira número 295, Bairro São Pedro,
              Matozinhos-MG.{" "}
              <span>
                <b>CEP:</b> 35 720-000.
              </span>
            </p>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.4617476947346!2d-44.07697102155524!3d-19.56470887994036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa6673408149995%3A0x4e23ad998270c067!2sR.%20Jo%C3%A3o%20Gon%C3%A7alves%20de%20Oliveira%2C%20295%20-%20Floresta%2C%20Matozinhos%20-%20MG%2C%2035720-000!5e0!3m2!1spt-BR!2sbr!4v1690193898414!5m2!1spt-BR!2sbr"
            className="map"
            width="320"
            height="170"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>

          <div className="text2">
            <p className="top">
              Alguma dúvida? <b>Fale conosco!</b>
            </p>

            <ul className="bottom">
              <li>
                <figure>
                  <img src={whats} alt="Whatsapp" />
                </figure>

                <p>(21) 99947-2482</p>
              </li>

              {/* <li>
                <figure>
                  <img src={whats} alt="Whatsapp" />
                </figure>

                <p>(11) 99363-1129</p>
              </li> */}

              <li>
                <figure>
                  <img src={email} alt="Email" />
                </figure>

                <p>contato@abcfarmamoveis.com.br</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">Copyright © 2023 abcfarma móveis</p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
